import React from 'react'
import PropTypes from 'prop-types'

export const TwitterIcon = ({ size, fill, ...rest }) => {
		return (
	        <svg { ...rest } fill={ fill } version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={ `${ size }px` } height={ `${ size }px` } viewBox="0 0 24 24">
                <path d="m7.8436 20.89c8.4389 0 13.057-6.9981 13.057-13.057 0-0.19688 0-0.39376-0.0089-0.59063 0.8949-0.64433 1.6735-1.4587 2.2909-2.3804-0.82331 0.36691-1.7093 0.60853-2.64 0.72487 0.94859-0.56379 1.6735-1.4676 2.0225-2.5415-0.88595 0.52799-1.8703 0.90385-2.9174 1.1097-0.84121-0.8949-2.0314-1.4497-3.3469-1.4497-2.5326 0-4.5908 2.0583-4.5908 4.5908 0 0.35796 0.04475 0.70697 0.11634 1.047-3.8123-0.18793-7.195-2.0225-9.4591-4.7967-0.39376 0.68012-0.61748 1.4676-0.61748 2.3088 0 1.5929 0.81436 2.9979 2.0404 3.8212-0.75172-0.026847-1.4587-0.23267-2.0762-0.57274v0.062643c0 2.2194 1.584 4.0807 3.678 4.5013-0.38481 0.10739-0.78751 0.16108-1.2081 0.16108-0.29532 0-0.58168-0.02685-0.8591-0.08054 0.58168 1.8256 2.282 3.15 4.2866 3.1858-1.575 1.235-3.5528 1.9688-5.7005 1.9688-0.36691 0-0.73382-0.0179-1.0918-0.06264 2.0225 1.2887 4.4387 2.0493 7.025 2.0493" />
            </svg>
		)
}


TwitterIcon.propTypes = {
    size: PropTypes.number.isRequired,
    fill: PropTypes.string.isRequired,
}

TwitterIcon.defaultProps = {
    size: 24,
    fill: '#000',
}