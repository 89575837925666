import React from 'react'

export const CloseIcon = ({ size = 24, fill = '#000', ...props }) => (
    <svg { ...props } version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={ `${ size }px` } height={ `${ size }px` } viewBox="0 0 24 24">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill={ fill } />
        <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
)


